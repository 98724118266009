<template>
  <div
    class="ui-field"
    :class="{'--invalid':innerErrors.length, '--disabled':disabled, '--required':required}"
  >
    <label v-text="label"></label>
    <div class="ui-field-body">
      <div class="ui-field-contents">
        <slot name="default"></slot>
      </div>
      <div
        class="ui-field-messages"
        v-if="innerMessages.length"
      >
        <p
          v-for="(msg,i) in innerMessages"
          :key="i"
        >{{ msg }}</p>
      </div>
      <div
        class="ui-field-errors"
        v-if="innerErrors.length"
      >
        <p
          v-for="(err,i) in innerErrors"
          :key="i"
        >{{ err }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-field',

  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },

    value: {
      required: false,
      default: null
    },

    required: {
      type: Boolean,
      default: false
    },

    message: {
      type: [String, Array],
      required: false,
      default: () => []
    },

    error: {
      type: [String, Array],
      required: false,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      innerMessages: [],
      innerErrors: []
    };
  },

  watch: {
    message: {
      immediate: true,
      handler(msg) {
        this.setMessage(msg);
      }
    },

    error: {
      immediate: true,
      handler(err) {
        this.setError(err);
      }
    }
  },

  methods: {
    setMessage(msg) {
      if (!msg) {
        return;
      }
      this.innerMessages = Array.isArray(msg) ? msg : [msg];
    },

    addMessage(msg) {
      this.innerMessages.push(msg);
    },

    setError(err) {
      if (!err) {
        this.innerErrors = [];
        return;
      }

      this.innerErrors = Array.isArray(err) ? err : [err];
    },

    addError(err) {
      this.innerErrors.push(err);
    }
  }
};
</script>

<style lang="scss">
.ui-field {
  & > label {
    display: block;
    color: #444;
  }

  .ui-field-messages,
  .ui-field-errors {
    padding: 0;
    margin-bottom: 12px;

    p {
      margin: 0;
      font-size: 12px;
    }
  }

  .ui-field-errors {
    color: var(--ui-color-danger);
  }
}

.ui-field.--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.ui-field.--required {
  & > label::after {
    content: ' *';
    color: var(--ui-color-danger);
  }
}

// Behavior: nested fields only breathe evenly horizontally
// .ui-field-contents .ui-field {
//   margin-top: 0;
//   margin-bottom: 0;
//   margin-left: 0;

//   &:last-child {
//     margin-right: 0;
//   }
// }

// Spacing
.ui-field {
  // padding: var(--ui-padding-vertical) 0;

  // space nested fields
  .ui-field {
    padding-right: var(--ui-padding-horizontal);
  }
}

// Behavior: fields inside ui-row
.ui-row > .ui-field {
  align-self: baseline;
}

// Behavior: fields inside ui-column
.ui-column > .ui-field {
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
    border-bottom: 0;
  }
}

.ui-field.--wide {
  display: flex;
  padding-bottom: 9px;

  & > label {
    width: 200px;
    min-width: 200px;
    margin-right: 1em;

    padding: var(--ui-padding);
  }

  & > .ui-field-body {
    flex: 1;
  }
}

@media (min-width: 600px) {
  .ui-column > .ui-field {
    display: flex;
    padding-bottom: 9px;

    & > label {
      width: 200px;
      min-width: 200px;
      margin-right: 1em;
      padding: var(--ui-padding);
    }
  }
}
</style>